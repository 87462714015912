import { getAPIURL } from 'config/constants';
import axios from 'axios';

export function getDeliveriesFromTokenZip(token: string, pictureIds: Array<any>, userRole: string) {
  let pictureIdsParam = '';

  for (let i = 0; i < pictureIds.length; i++) {
    pictureIdsParam += `&picture_ids=${pictureIds[i]}`;
  }

  if (pictureIdsParam !== '') {
    return `${getAPIURL()}/getDeliveriesFromTokenZip?distribution_token=${token}${pictureIdsParam}&user_role=${userRole}`;
  }

  return `${getAPIURL()}/getDeliveriesFromTokenZip?distribution_token=${token}&user_role=${userRole}`;
}

export async function getDetailedFloorplanFromTokenZip(token: string) {
  return axios
    .get(`${getAPIURL()}/getDetailedFloorplanFromTokenZip?distribution_token=${token}`)
    .then((response) => {
      const urls = response.data;
      return urls;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
}

export function downloadHDRS(listingId: number, shootingId: number) {
  return `${getAPIURL()}/downloadHDRS?listing_id=${listingId}&shooting_id=${shootingId}`;
}

export async function markDropboxPictureAsDownloaded(token: string, files: string[]) {
  try {
    const config = { headers: { token } };
    const params = {
      files,
    };
    const res = await axios.post(`${getAPIURL()}/markDropboxPictureAsDownloaded`, params, config);
    return res;
  } catch (err) {
    console.log('[markDropboxPictureAsDownloaded] error: ', err);
  }
  return null;
}

export async function getUploadLink(token: string, files: any[]) {
  try {
    const config = { headers: { token } };
    const params = {
      files,
    };
    const res = await axios.post(`${getAPIURL()}/getGCPUploadLink`, params, config);
    return res.data.files;
  } catch (err) {
    console.log('[getUploadLink] error: ', err);
  }
  return null;
}

export async function getUploadLinkForSingleFile(token: string, file: any) {
  try {
    const config = { headers: { token } };
    const params = {
      file: {
        uploadPath: file.uploadPath,
        type: file.type,
      },
    };
    const res = await axios.post(`${getAPIURL()}/getGCPUploadLink`, params, config);
    return res.data.file.uploadUrl;
  } catch (err) {
    console.log('[getUploadLink] error: ', err);
  }
  return null;
}

export async function getUploadLinkForProdInject(token: string, files: any[]) {
  try {
    const config = { headers: { token } };
    const params = {
      files,
    };
    const res = await axios.post(`${getAPIURL()}/getUploadLinkForInject`, params, config);
    return res.data.files;
  } catch (err) {
    console.log('[getUploadLink] error: ', err);
  }
  return null;
}

export async function getProcessedHDRSForReview(shooting_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const res = await axios.get(
      `${getAPIURL()}/getProcessedHDRSForReview?shooting_id=${shooting_id}`,
      config,
    );
    return res.data.files;
  } catch (err) {
    console.log('[getProcessedHDRSForReview] error: ', err);
  }
  return null;
}

export async function fetchDownloadLink(filePath: string, token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/getGCPDownloadLink`, { filePath }, config);
    return response.data.downloadUrl;
  } catch (error) {
    console.log('[fetchDownloadLink] error: ', error);
  }
}

export async function createFileCompressorJob(downloadData: any, token: string) {
  try {
    const { listing_id, download_type, shooting_id, email } = downloadData;
    const config = { headers: { token } };
    const params = {
      listing_id,
      download_type,
      shooting_id,
    };
    const response = await axios.post(`${getAPIURL()}/createFileCompressorJob`, params, config);
    return response;
  } catch (err) {
    console.log('[getProcessedHDRSForReview] error: ', err);
  }
  return null;
}

export async function generateDownloadUrlForPostProd(
  token: string,
  listing_id: number,
  shooting_id: number,
) {
  try {
    const config = { headers: { token } };
    const params = {
      listing_id,
      shooting_id,
    };
    const res = await axios.post(`${getAPIURL()}/createDownloadLinkForPostProd`, params, config);
    return res.data.download_url;
  } catch (err) {
    console.log('[generateDownloadUrlForPostProd] error: ', err);
  }
}

export async function setFileMetadata(
  token: string,
  file_path: string,
  metadata: object,
  bucket_name: string,
) {
  try {
    const config = { headers: { token } };
    const params = {
      file_path,
      metadata,
      bucket_name,
    };
    const res = await axios.post(`${getAPIURL()}/changeFileMetadata`, params, config);
    return res;
  } catch (err) {
    console.log('[generateDownloadUrlForPostProd] error: ', err);
  }
}

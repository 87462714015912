import { useSelector, useDispatch } from 'react-redux';
import {
  addFavoriteListingsAsync,
  fetchBrokerPublicPortalInfos,
  fetchFavoriteListingsAsync,
  removeFavoriteListingsAsync,
  verifyDomainNameAsync,
  fetchDemoListingsAsync,
  fetchBrokerPortalListingsAsync,
  fetchServiceOfferDemoPicturesAsync,
  fetchClientPageInfoAsync,
} from 'state/actions';
import { useState, useEffect } from 'react';
import { LOADING, IDLES } from 'state/constants';
import { Broker, Listing } from 'models';
import { updateBrokerPublicPageInfosAsync } from 'state/slices/publicPersonalPortal';

export const usePortalInfos = (token?: string, domain_name?: string, language?: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const {
    favorite_listings,
    broker,
    broker_portal_infos,
    isDomainAvailable,
    isCustomDomainAvailable,
    savedInfoStatus,
    isDomainTaken,
    saveBgImageStatus,
    saveBannerImageStatus,
    saveBannerProfileImageStatus,
    saveFaviconStatus,
    saveBannerVideoStatus,
    saveLogoImageStatus,
    no_domain_found,
    centris_listings,
    centris_listings_from_db,
    all_templates,
    google_reviews,
    sections,
  } = useSelector((state: any) => {
    return state.personalPortal;
  });
  const loadPortalInfos = async () => {
    const isIdle = IDLES.includes(loading);
    if (isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchBrokerPublicPortalInfos(token, domain_name, language));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadPortalInfos();
  }, [domain_name]);

  return {
    broker,
    favorite_listings,
    broker_portal_infos,
    loading,
    isDomainAvailable,
    isCustomDomainAvailable,
    savedInfoStatus,
    isDomainTaken,
    saveBgImageStatus,
    saveBannerImageStatus,
    saveBannerProfileImageStatus,
    saveFaviconStatus,
    saveBannerVideoStatus,
    saveLogoImageStatus,
    no_domain_found,
    centris_listings,
    centris_listings_from_db,
    all_templates,
    sections,
    loadPortalInfos,
    google_reviews,
  };
};

export const useAddFavoriteListing = (
  token: string,
  listings: Array<Listing>,
  broker_id: string,
) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    added: false,
    adding: LOADING.NONE,
  });

  const addFavoriteListing = async (token: string, listings: Array<Listing>, broker_id: string) => {
    const isIdle = IDLES.includes(state.adding);
    if (token && isIdle) {
      setState({ added: false, adding: LOADING.STARTED });
      await dispatch(addFavoriteListingsAsync(token, listings, broker_id));
      setState({ added: true, adding: LOADING.FINISHED });
    }
  };

  return {
    ...state,
    addFavoriteListing,
  };
};

export const usePortalListings = (token: string, broker_id: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);
  const { favorite_listings } = useSelector((state: any) => {
    return state.personalPortal;
  });

  const loadFavoriteListings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchFavoriteListingsAsync(token, broker_id));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadFavoriteListings();
  }, [token]);

  return { loading, favorite_listings };
};

export const useRemoveFavoriteListing = (token: string, listing_id: string, broker_id: string) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    removed: false,
    removing: LOADING.NONE,
  });

  const removeFavoriteListing = async (token: string, listing_id: string, broker_id: string) => {
    const isIdle = IDLES.includes(state.removing);
    if (token && isIdle) {
      setState({ removed: false, removing: LOADING.STARTED });
      await dispatch(removeFavoriteListingsAsync(token, listing_id, broker_id));
      setState({ removed: true, removing: LOADING.FINISHED });
    }
  };

  return {
    ...state,
    removeFavoriteListing,
  };
};

export const useUpdatePortalInfo = (token: string, domain_name: string) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
    error: false,
  });

  const saveBrokerPublicPageInfos = (
    token: string,
    is_domain_required: boolean,
    domain_name: string,
    custom_domain_name: string,
    bio_fr: string,
    bio_en: string,
    display_name: string,
    email: string,
    phone_number: number,
    pixelID: number,
    instagram: string,
    facebook: string,
    twitter: string,
    linkedin: string,
    youtube: string,
    tiktok: string,
    website: string,
    address: string,
    slogan_fr: string,
    slogan_en: string,
    template: number,
    template_style: any,
    banner_image: string,
  ) => {
    const isIdle = IDLES.includes(state.updating);
    if (token && isIdle) {
      setState({ updated: false, updating: LOADING.STARTED, error: false });
      const res = dispatch(
        updateBrokerPublicPageInfosAsync(
          token,
          is_domain_required,
          domain_name,
          custom_domain_name,
          bio_fr,
          bio_en,
          display_name,
          email,
          phone_number,
          pixelID,
          instagram,
          facebook,
          twitter,
          linkedin,
          youtube,
          tiktok,
          website,
          address,
          slogan_fr,
          slogan_en,
          template,
          template_style,
          banner_image,
        ),
      );
      setState({ updated: true, updating: LOADING.FINISHED, error: false });
    }
  };

  return {
    ...state,
    saveBrokerPublicPageInfos,
  };
};

export const useVerifyDomain = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    verified: false,
    verifying: LOADING.NONE,
  });
  const verifyDomainName = async (token: string, domain_name: string, type: string) => {
    const isIdle = IDLES.includes(state.verifying);
    if (token && isIdle) {
      setState({ verified: false, verifying: LOADING.STARTED });
      await dispatch(verifyDomainNameAsync(token, domain_name, type));
      setState({ verified: true, verifying: LOADING.FINISHED });
    }
  };
  return {
    ...state,
    verifyDomainName,
  };
};

export const usePortalData = (token: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { demo_listings } = useSelector((state: any) => {
    return state.personalPortal;
  });
  const loadDemoListing = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchDemoListingsAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };
  useEffect(() => {
    loadDemoListing();
  }, [demo_listings]);
  return {
    loading,
    demo_listings,
  };
};

export const useBrokerAllPortalListings = (token: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { broker_portal_listings } = useSelector((state: any) => {
    return state.personalPortal;
  });
  const loadBrokerPortalListings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchBrokerPortalListingsAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadBrokerPortalListings();
  }, [token]);

  return { loading, broker_portal_listings };
};
export const useServiceOfferDemoPictures = () => {
  const dispatch = useDispatch();

  const { service_offer_demo_pictures } = useSelector((state: any) => {
    return state.personalPortal;
  });
  const loadServiceOfferDemoPictures = async () => {
    await dispatch(fetchServiceOfferDemoPicturesAsync());
  };
  useEffect(() => {
    loadServiceOfferDemoPictures();
  }, []);
  return { service_offer_demo_pictures };
};

export const useCustomPortalSections = () => {
  const {
    sections,
    broker_portal_infos_id,
    section,
    selectedSection,
    selectedContent,
    editMode,
    contentFocus,
    broker_portal_infos,
    currentSectionTab,
    showSideBar,
    google_reviews,
    currentSection,
    client_pages,
    broker,
    showAnimation,
    savedMediaStatus,
    savedInfoStatus,
    showClientEditPage,
    dataChanged,
    reorderedSections,
  } = useSelector((state: any) => {
    return state.personalPortal;
  });
  return {
    sections,
    broker_portal_infos_id,
    section,
    selectedSection,
    selectedContent,
    editMode,
    contentFocus,
    broker_portal_infos,
    currentSectionTab,
    showSideBar,
    google_reviews,
    currentSection,
    client_pages,
    broker,
    showAnimation,
    savedMediaStatus,
    savedInfoStatus,
    showClientEditPage,
    dataChanged,
    reorderedSections,
  };
};

export const useClientPageInfo = (link: string) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { client_page_info } = useSelector((state: any) => {
    return state.personalPortal;
  });
  const broker = new Broker(client_page_info?.broker_portal_infos?.broker);
  const loadClientPageInfo = async () => {
    const isIdle = IDLES.includes(loading);
    if (link && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchClientPageInfoAsync(link));
      setLoading(LOADING.FINISHED);
    }
  };
  useEffect(() => {
    loadClientPageInfo();
  }, [link]);
  return {
    loading,
    client_page_info,
    broker,
  };
};

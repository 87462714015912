import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { ServiceOffer, ServiceOfferDetails } from 'models';
import { useIntl } from 'react-intl';
import { useLanguage } from 'hooks';
import { ENG } from 'config/constants';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    minWidth: 500,
    '@media (max-width: 767px)': {
      minWidth: 'auto',
    },
    maxWidth: 700,
  },
  summaryItemBox: {
    paddingTop: 10,
  },
  summaryItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    padding: 5,
  },
  taxesText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    padding: 5,
  },
});

interface HvDetailedBillProps {
  service_offers: ServiceOfferDetails[];
}

function HvDetailedBill(props: HvDetailedBillProps) {
  const styles = useStyles();
  const intl = useIntl();
  const language = useLanguage();
  const { service_offers } = props;
  const [groupedServiceOffers, setGroupedServiceOffers] = useState({});
  const [detailedPricing, setDetailedPricing] = useState({
    subTotal: 0,
    qst: 0,
    gst: 0,
    total: 0,
  });

  const fmtTitle = intl.formatMessage({ id: 'deliver.additionalPictures.reviewTabTitle' });
  const fmtSubtotal = intl.formatMessage({ id: 'shooting.detail.subTotal' });
  const fmtQST = intl.formatMessage({ id: 'shooting.detail.qst' });
  const fmtGST = intl.formatMessage({ id: 'shooting.detail.gst' });
  const fmtTotal = intl.formatMessage({ id: 'shooting.detail.total' });

  useEffect(() => {
    const groupedSO = {};
    for (let i = 0; i < service_offers.length; i++) {
      if (groupedSO[service_offers[i].id]) {
        groupedSO[service_offers[i].id].count++;
      } else {
        const serviceOfferDetails = service_offers[i];
        groupedSO[service_offers[i].id] = {
          ...serviceOfferDetails,
          count: 1,
        };
      }
    }

    setGroupedServiceOffers(groupedSO);
  }, [service_offers]);

  useEffect(() => {
    const pricing = {
      subTotal: 0,
      qst: 0,
      gst: 0,
      total: 0,
    };

    for (const key in groupedServiceOffers) {
      if (groupedServiceOffers[key].count > 0) {
        pricing.subTotal += groupedServiceOffers[key].price * groupedServiceOffers[key].count;
      }
    }

    pricing.qst = pricing.subTotal * (9.975 / 100);
    pricing.gst = pricing.subTotal * (5 / 100);
    pricing.total = parseFloat((pricing.subTotal + pricing.qst + pricing.gst).toFixed(2));

    setDetailedPricing(pricing);
  }, [groupedServiceOffers]);

  return (
    Object.keys(groupedServiceOffers).length !== 0 &&
    detailedPricing.total !== 0 && (
      <div className={styles.content}>
        <Typography variant='h5' style={{ opacity: 0.5 }}>
          {fmtTitle}
        </Typography>
        <div className={styles.summaryItemBox}>
          {Object.keys(groupedServiceOffers).map((key: string) => {
            const serviceOffer = groupedServiceOffers[key];
            const itemName =
              language === ENG ? serviceOffer.display_name : serviceOffer.display_name_fr;
            return (
              <Typography key={key} className={styles.summaryItems}>
                <span>
                  {`${itemName} ${serviceOffer.count > 1 ? `x ${serviceOffer.count}` : ''} `}
                </span>
                {`${serviceOffer.price * serviceOffer.count}$`}
              </Typography>
            );
          })}
          <Divider />
          <Typography className={styles.summaryItems}>
            <span>{fmtSubtotal}</span>
            {`${detailedPricing.subTotal.toFixed(2)}$`}
          </Typography>
          <Divider />
          <Typography className={styles.taxesText}>
            <span>{fmtQST}</span>
            {`${detailedPricing.qst.toFixed(2)}$`}
          </Typography>
          <Typography className={styles.taxesText}>
            <span>{fmtGST}</span>
            {`${detailedPricing.gst.toFixed(2)}$`}
          </Typography>
          <Divider />
          <Typography className={styles.summaryItems}>
            <span>{fmtTotal}</span>
            {`${detailedPricing.total}$`}
          </Typography>
        </div>
      </div>
    )
  );
}

export default HvDetailedBill;

import axios from 'axios';
import { getAPIURL } from 'config/constants';

export async function setPictureURL(
  picture_resolution_type: number,
  listing_id: number,
  shooting_id: number,
  picture_index: string,
  picture_url: string,
  token: string,
) {
  try {
    const params = {
      picture_resolution_type,
      listing_id,
      shooting_id,
      picture_index,
      picture_url,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/setPictureURL`, params, config);
    return response;
  } catch (error) {
    console.log('[setPictureURL] error: ', error);
  }
  return null;
}

export async function updateServiceOffer(
  service_offer_id: number,
  media_url: string,
  token: string,
) {
  try {
    const params = {
      id: service_offer_id,
      url: media_url,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/updateServiceOffer`, params, config);
    return response;
  } catch (error) {
    console.log('[updateServiceOffer] error: ', error);
  }
  return null;
}

export async function saveDetailedFloorplan(
  url: string,
  media_type: string,
  service_offer_id: number,
  language: string,
  token: string,
) {
  try {
    const params = {
      service_offer_id,
      url,
      media_type,
      language,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/saveDetailedFloorplan`, params, config);
    return response;
  } catch (err) {
    console.log('[saveDetailedFloorplan] error: ', err);
  }
  return null;
}

export async function createPictureEditEsoftOrder(
  shooting_id: number,
  orderContent: any[],
  token: string,
) {
  try {
    const params = {
      shooting_id,
      orderContent,
      orderType: 'pictureEditing',
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/createESoftOrder`, params, config);
    return response;
  } catch (err) {
    console.log('[createPictureEditEsoftOrder] error: ', err);
    throw err;
  }
}

export async function preparePictureForDelivery(
  listing_id: number,
  shooting_id: number,
  picture_url: string,
  shouldPrioritize: boolean,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
      picture_url,
      shouldPrioritize,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/preparePictureForDelivery`, params, config);
    return response;
  } catch (err) {
    console.log('[preparePictureForDelivery] error: ', err);
  }
  return null;
}

export async function getFilesHashMapFromCloud(prefix: string, token: string) {
  try {
    const params = {
      prefix,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/getFilesHashMapFromCloud`, params, config);
    return response.data.filesHash;
  } catch (err) {
    console.log('[getFilesFromCloud] error: ', err);
  }
  return null;
}

export async function createFileAnalysisJob(
  listing_id: number,
  shooting_id: number,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
      upload_completed: true,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/createFileAnalysisJob`, params, config);
    return response;
  } catch (err) {
    console.log('[createFileAnalysisJob] error: ', err);
  }
  return null;
}

export async function create3DProcessingJob(listing_id: number, token: string) {
  try {
    const params = {
      listing_id,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/create3DProcessingJob`, params, config);
    return response;
  } catch (err) {
    console.log('[create3DProcessingJob] error: ', err);
  }
}

export async function getFilesNameAndSizeGCP(
  listing_id: number,
  shooting_id: number,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/getFilesNameAndSizeGCP`, params, config);
    return response.data;
  } catch (err) {
    console.log('[getFilesNameAndSizeGCP] error: ', err);
  }
}

export async function reprocessAllMarkers(listing_id: number, token: string) {
  try {
    const params = {
      listing_id,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/reprocessAll3DMarkers`, params, config);
    return response;
  } catch (err) {
    console.log('[reprocessAllMarkers] error: ', err);
  }
}

export async function reprocessAllPictures(
  listing_id: number,
  shooting_id: number,
  use_mef_stacker: boolean,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
      use_mef_stacker,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/reprocessAllPictures`, params, config);
    return response;
  } catch (err) {
    console.log('[reprocessAllPictures] error: ', err);
  }
}

export async function createRedisInjectorEntry(
  listing_id: number,
  shooting_id: number,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/createRedisInjectorEntry`, params, config);
    return response;
  } catch (error) {
    console.log('[createRedisInjectorEntry] error: ', error);
  }
}

export async function createImagenEditJob(
  listing_id: number,
  shooting_id: number,
  picture_ids: Array<number>,
  token: string,
) {
  try {
    const params = {
      listing_id,
      shooting_id,
      picture_ids,
    };
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/createImagenEditJob`, params, config);
    return response;
  } catch (error) {
    console.log('[createImagenEditJob] error: ', error);
  }
}

import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { images } from 'assets/images';

const useStyles = makeStyles({
  modal: {
    margin: 'auto',
    padding: 16,
  },
  titleBar: {
    fontWeight: 600,
    backgroundColor: '#00bdf2',
    color: 'white',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 15,
    textAlign: 'left',
  },
  headerImage: {
    maxHeight: 30,
    width: 'auto',
    right: 80,
    top: 12,
    position: 'absolute',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'white',
  },
  contentBox: {
    flex: '1 1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  buttonsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 24px',
  },
});

interface Props {
  className?: string;
  title?: string;
  id?: string;
  children: any;
  buttons?: any;
  headerImage?: string;
  largerWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullScreen?: boolean;
  zIndex?: number;
  onClose: () => void;
}

function HvModal({
  className,
  title,
  children,
  buttons,
  onClose,
  headerImage,
  largerWidth,
  maxWidth,
  fullScreen,
  id,
  zIndex,
}: Props) {
  const styles = useStyles();

  return (
    <Dialog
      maxWidth={largerWidth ? false : maxWidth || 'sm'}
      style={
        largerWidth ? { width: 'fit-content', zIndex: zIndex || 1000 } : { zIndex: zIndex || 1000 }
      }
      fullScreen={fullScreen}
      className={classNames(className, styles.modal)}
      onClose={onClose}
      open
    >
      {title && (
        <DialogTitle className={styles.titleBar}>
          <div>
            <Typography className={styles.title}>{title}</Typography>
          </div>
          <div>
            {headerImage && (
              <img src={images[headerImage]} className={styles.headerImage} alt='Icon' />
            )}
            <IconButton className={styles.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
      )}

      {children && (
        <DialogContent id={id || ''} className={styles.contentBox}>
          {children}
        </DialogContent>
      )}

      {buttons && <DialogActions className={styles.buttonsBox}>{buttons}</DialogActions>}
    </Dialog>
  );
}

HvModal.defaultProps = {
  className: '',
  title: '',
  children: null,
  buttons: null,
  headerImage: '',
  onClose: _.noop,
};

HvModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  buttons: PropTypes.any,
  headerImage: PropTypes.string,
  onClose: PropTypes.func,
};

export default HvModal;
